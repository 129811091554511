import React from "react";
import Faqs from "./Faqs";


export default function FaqPage() {
  return (
    <>


<Faqs/>
<br/>
<br/>
<br/>
<br/>
</>
  );
}
